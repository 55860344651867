<template>
  <div>
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }"> Client List </b-link>
        for other clients.
      </div>
    </b-alert>
    <div v-if="clientData && Object.keys(clientData).length > 0">
      <b-card no-body class="p-1 px-2">
        <!-- Client Info: Top col -->
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
            Customer Information
          </h4>
          <div class="d-flex detail-top-b-list flex-wrap justify-content-end">
            <b-button
              class="px-3"
              :to="{ name: 'clients-edit', params: { id: clientData.id } }"
              variant="primary"
              ><feather-icon class="mr-1" size="14" icon="Edit2Icon" />Edit</b-button
            >
            <b-button
              class="px-3"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              class="px-3"
              variant="outline-danger"
              v-if="ability.can('delete', 'client_account')"
              @click="deleteClient(clientData.id)"
              ><feather-icon class="mr-1" size="14" icon="Trash2Icon" />Delete</b-button
            >
          </div>
        </div>
        <hr />
        <!-- Client Table: Left col -->
        <b-row class="info-wrap px-1">
          <b-col title="name(en)" cols="12" sm="12" md="4">
            <h4 class="mb-0 font-weight-bolder">{{ clientData.first_name }} {{ clientData.last_name }}</h4>
          </b-col>

          <b-col title="email" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="MailIcon" />
            {{ clientData.email }}
          </b-col>

          <b-col title="is active?" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="UserCheckIcon" />
            <template>
              <feather-icon v-if="clientData.is_active" size="14" icon="CheckIcon" />
              <feather-icon v-else size="14" icon="XIcon" />
            </template>
          </b-col>
          
          <b-col cols="12" sm="6" md="4">
            Account Opened Date:
            {{ getCorrectDateTime(clientData.created_at) }}
          </b-col>

          <!-- <b-col title="Date of Birth" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="CalendarIcon" />
            {{ clientData.date_of_birth }}
          </b-col> -->

         
          <hr class="w-100 d-md-none" />
          <!-- <b-col title="Address" cols="12" sm="8">
            <feather-icon class="mr-1" size="14" icon="MapPinIcon" />
            {{ clientData.address }}
          </b-col> -->
        </b-row>
      </b-card>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    draggable,
    vSelect,
  },
  data() {
    return {
      ability,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    const clientData = ref({});
    const genders = ref([]);
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";

    const client = {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      gender_id: 1,
      phone_number: "",
      referred_code: null,
      is_active: 1,
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-client/fetchClient", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          clientData.value = response.data.client;
        })
        .catch((error) => {
          console.log("error when fetching client", error);
          if (error.response.status === 404) {
            clientData.value = undefined;
          }
        });
    };

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { genders: true })
        .then((response) => {
          genders.value = response.data.genders;
        });
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    return {
      clientData,
      refetchData,
      genders,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.info-wrap {
  .col-12 {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
  }

  .tag-list {
    a {
      margin-right: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.drop-btn {
  margin-top: 5px;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  background-color: rgba(148, 200, 187, 0.2);

  svg {
    transition: all 300ms ease;
    top: 2px;
    right: 0px;
  }

  &.not-collapsed {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.drop-item {
  display: block;
  margin-top: 5px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid rgba(189, 189, 189, 0.5);
}
</style>
